// npm
import React from 'react'
import GoogleMapReact from 'google-map-react'
import gmapStyles from 'constants/gmap-styles'
import { Box } from '@mui/material'
// src
import AALogo from 'images/aa-logo.svg'

interface MapProps extends GoogleMapReact.Props {
  longitude: number
  latitude: number
}

const MapMarker = ({ ...props }: GoogleMapReact.Coords) => (
  <Box
    component="div"
    width="76px"
    height="97px"
    sx={{ transform: 'translate3d(-50%,-100%,0)' }}
  >
    <Box
      component="div"
      width="76px"
      height="76px"
      borderColor="primary.main"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        background: 'white',
        borderStyle: 'solid',
        borderWidth: '4px',
        position: 'relative',
        svg: {
          width: '30px',
        },
        ':before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          bottom: '-10px',
          marginLeft: '-10px',
          left: '50%',
          width: '20px',
          height: '20px',
          transform: 'rotate(-45deg)',
          backgroundColor: 'primary.main',
          zIndex: -1,
        },
        ':after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          bottom: '-25px',
          marginLeft: '-12px',
          left: '50%',
          width: '24px',
          height: '8px',
          borderRadius: '50%',
          backgroundColor: 'rgba(0,0,0,0.1)',
        },
      }}
    >
      <AALogo />
    </Box>
  </Box>
)

const Map = ({ longitude, latitude }: MapProps) => {
  const defaultProps = {
    center: {
      lat: latitude,
      lng: longitude,
    },
    zoom: 17,
    options: {
      styles: gmapStyles,
    },
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: `${process.env.GATSBY_GMAP_API_KEY}` }}
      {...defaultProps}
      yesIWantToUseGoogleMapApiInternals
    >
      <MapMarker lat={latitude} lng={longitude} />
    </GoogleMapReact>
  )
}

export default Map
