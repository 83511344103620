// npm
import React from 'react'
import { graphql } from 'gatsby'
import { Box, Container, Grid, Link, styled, Typography } from '@mui/material'
// src
import ContactTypeButton from '@atoms/buttons/ContactTypeButton'
import Button from '@atoms/buttons/Button'
import Divider from '@atoms/layout/Divider'
import LineBreaks from '@atoms/text/LineBreaks'
import Map from '@atoms/maps/Map'
import PhoneLink from '@atoms/links/PhoneLink'
import SocialIcons from '@molecules/social/SocialIcons'
import ContentSectionBgImage from '@molecules/sections/components/ContentSectionBgImage'
import Layout from '@organisms/global/Layout'

interface Props {
  data: Queries.ContactPageQuery
}

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    maxWidth: '600px',
  },
}))
const AddressBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: 'white',
  width: '100%',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    width: '150%',
    position: 'relative',
    zIndex: 10,
    top: '50%',
    transform: 'translateY(-50%)',
  },
}))

const ContactGridItem = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.down('lg')]: {
    ['&:first-of-type']: {
      paddingTop: 0,
    },
    ['&:last-of-type']: {
      paddingBottom: 0,
    },
  },
  ['&:not(:first-of-type)']: {
    borderTop: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.only('lg')]: {
      border: 'none',
    },
    [theme.breakpoints.up('xl')]: {
      borderTop: 'none',
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
}))

const ContactPage = ({ data }: Props) => {
  const d = data.datoCmsContactPage
  return (
    <Layout
      metadata={{
        seoTags: d.seoMetaTags,
      }}
      headerProps={{
        sizeVariant: 'compact',
        themeVariant: 'dark',
        forceSolidNav: true,
      }}
    >
      <ContentSectionBgImage
        keyId={d.id}
        mainHeading={d.mainHeadingNode.childMarkdownRemark.html}
        subtitle={d.smallHeading}
        text={d.heroTextNode.childMarkdownRemark.html}
        imageLandscape={d.heroImageLandscape.gatsbyImageData}
        imagePortrait={d.heroImagePortrait.gatsbyImageData}
        hero
      />
      <StyledContainer
        maxWidth="xl"
        sx={{ background: 'white', py: { xs: 6, md: 8, xl: 10 } }}
      >
        <Grid container spacing={0}>
          {data.allDatoCmsContactType.edges.map((item: any) => {
            const type: Queries.DatoCmsContactType = item.node
            return (
              <ContactGridItem key={type.id} item xs={12} lg={4}>
                <ContactTypeButton
                  slug={type.slug}
                  ariaLabel={type.ariaLabel}
                  showHeading
                  containerProps={{
                    mb: 2,
                  }}
                  iconButtonProps={{
                    disabled: true,
                  }}
                />
                {type.slug === 'telephone-number' && (
                  <PhoneLink
                    phoneNumber={d.telephoneNumber}
                    sx={{ typography: 'bodyContact' }}
                    color="inherit"
                    className="primary-hover"
                  />
                )}
                {type.slug === 'email' && (
                  <Link
                    href={`mailto:${d.email}`}
                    sx={{ typography: 'bodyContact' }}
                    color="inherit"
                    className="primary-hover"
                  >
                    {d.email}
                  </Link>
                )}
                {type.slug === 'social' && <SocialIcons variant="body" />}
              </ContactGridItem>
            )
          })}
        </Grid>
      </StyledContainer>
      <Container
        maxWidth="xl"
        sx={{
          pt: { xs: 0 },
          pb: { xs: 7, sm: 8, md: 9, lg: 10 },
        }}
      >
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            md={9}
            sx={{
              order: {
                xs: 1,
                md: 2,
              },
            }}
          >
            <Box
              width="100%"
              sx={{
                height: {
                  xs: '500px',
                  sm: '550px',
                  md: '600px',
                  lg: '700px',
                  xl: '800px',
                },
              }}
            >
              <Map
                longitude={d.addressLocation.longitude}
                latitude={d.addressLocation.latitude}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              order: {
                xs: 2,
                md: 1,
              },
            }}
          >
            <AddressBox
              px={{ xs: 3, sm: 4, md: 6, xl: 8 }}
              py={{ xs: 3, sm: 4, md: 6, xl: 10 }}
            >
              <Typography component="h2" variant="h5">
                {d.addressHeading}
              </Typography>
              <Divider mx="auto" my={2} />
              <Typography variant="body2">
                <LineBreaks
                  text={d.addressNode.childMarkdownRemark.html}
                  keyId={d.id}
                  breakpoint="xs"
                />
              </Typography>
              <Button
                sx={{ mt: 3, color: 'white' }}
                href={`https://www.google.com/maps/search/?api=1&query=${d.addressLocation.latitude},${d.addressLocation.longitude}`}
                target="_blank"
                rel="noopener"
              >
                {d.directionsButtonLabel}
              </Button>
            </AddressBox>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query ContactPage($originalId: String!) {
    datoCmsContactPage(originalId: { eq: $originalId }) {
      id
      telephoneNumber
      directionsButtonLabel
      addressHeading
      socialNetworks {
        id
        url
        platform {
          id
          name
          slug
        }
      }
      email
      addressNode {
        childMarkdownRemark {
          html
        }
      }
      addressLocation {
        latitude
        longitude
      }
      heroImageLandscape: heroImage {
        gatsbyImageData(
          width: 1600
          layout: FULL_WIDTH
          imgixParams: { ar: "16:9", fit: "crop", crop: "focalpoint" }
        )
      }
      heroImagePortrait: heroImage {
        gatsbyImageData(
          width: 900
          layout: FULL_WIDTH
          imgixParams: { ar: "1:2", fit: "crop", crop: "focalpoint" }
        )
      }
      mainHeadingNode {
        childMarkdownRemark {
          html
        }
      }
      smallHeading
      heroTextNode {
        childMarkdownRemark {
          html
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsContactType(sort: { fields: position }) {
      edges {
        node {
          id
          name
          ariaLabel
          slug
        }
      }
    }
  }
`
